@import "variables";

::-webkit-scrollbar {
  border-radius: 20px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $secondary;
  border-radius: 10px;
}

::-webkit-scrollbar-track-piece {
  background: $primary;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}

::selection {
  background: $secondary;
  /* WebKit/Blink Browsers */
  color: $primary;
}

::-moz-selection {
  background: $secondary;
  /* Gecko Browsers */
  color: $primary;
}

body {
  margin: 0;
  overflow-x: hidden;
  //background-color: $secondary;
  transition: 0.4s;
  background-image: linear-gradient(to bottom right, $secondary, white 40%, $secondary);
}

.nav-tabs .nav-link.active {
  background-color: $primary-dark;
}

.nav-tabs {
  border-bottom: 0;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: $secondary;
}

.nav-tabs .nav-link {
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
}

.nav-link {
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; // remove the gap so it doesn't close
}

.nav {
  z-index: 6;
  top: 0;
  position: sticky;
  width: calc(100% - 40px);
  max-width: 1200px;
}

.icon-bar {
  width: 22px;
  height: 2px;
  background-color: $secondary;
  display: block;
  transition: all 0.2s;
  margin-top: 4px;
}

.navbar-toggler {
  border: none;
  background: transparent !important;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

.content {
  background-color: $primary;
  color: $text-color;
  //height: calc(100% - 80px);
  width: calc(100% - 40px);
  /*left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);*/
  margin-bottom: 20px;
  max-width: 1200px;
  position: relative;
  z-index: 4;
  overflow-x: hidden;
  -moz-box-shadow: 0 0 40px #ccc;
  -webkit-box-shadow: 0 0 40px #ccc;
  box-shadow: 0 0 40px #ccc;
}

.content::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.inner-content h2 {
  text-align: center;
}

.service-desc {
  display: block;
  width: 100%;
}

.splash {
  background-image: linear-gradient(to bottom right, $primary 50%, $secondary);
  position: fixed;
  //background-image: radial-gradient($primary 60%, $secondary, $secondary-dark);
}

.dropdown-item:hover {
  background-color: $primary-dark;
}

.toggler {
  color: $secondary;
  font-size: 28px;
}

.full-width {
  min-width: 100%;
  width: 100%;
}

.crop-width {
  width: calc(100% - 40px);
  max-width: 1200px;
}

.full-height {
  min-height: 100%;
  height: 100%;
}

.auto-height {
  height: auto;
}

.auto-width {
  width: auto;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.header {
  z-index: -1;
  position: sticky;
  top: 0;
  left: 0;
  transition: 0.2s;
  height: 80vh;
}

.header-sm {
  z-index: 0;
  position: sticky;
  top: 0;
  left: 0;
  transition: 0.2s;
  height: 25vh;
}

.header-sub-sm {
  font-size: 2rem;
}

.header-img {
  max-width: 20%;
}

.sub-header {
  font-size: 32px;
  text-align: center;
  position: sticky;
  transform: translate(-50%);
  left: 50%;
  transition: 0.4s;
}

.sub-header-sm {
  font-size: 18px !important;
  left: 0% !important;
  transform: translate(0%) !important;
  position: sticky;
  padding-left: 20px;
}

.scroll-arrow {
  position: absolute;
  bottom: 0;
  text-align: center;
  cursor: pointer;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.logo img {
  max-width: 50%;
  max-height: 60%;
  object-fit: contain;
}

.logo-sm {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  height: 40%;
}

.logo-sm img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.logo-footer {
  max-width: 100%;
  max-height: 75px;
}

.logo-caption {
  display: block;
}

.logo-nav {
  width: 25%;
  //display: none;
  display: flex;
  align-items: center;
  //position: absolute;
  //top: 0;
  height: 50px;
}

.logo-nav img {
  //width: 100%;
  max-width: 100%;
  max-height: 90%;
  transition: 0.4s;
}

.sticky {
  position: sticky;
  top: -1;
}

.footer {
  position: sticky;
  width: calc(100% - 40px);
  max-width: 1200px;
  bottom: 0;
  z-index: 0;
}

.footer i {
  transition: 0.5s;
}

.footer-insta:hover {
  color: #cf426d !important;
}

.footer-facebook:hover {
  color: #3877ea !important;
}

a {
  text-decoration: none;
}

.arapey {
  font-family: "Arapey";
}

.rochester {
  font-family: "Rochester";
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.small {
  font-size: 1vw;
}

.medium {
  font-size: 2vh;
}

.medium-75 {
  font-size: 75%;
}

.large {
  font-size: 3vh;
}

.xlarge {
  font-size: 4vh;
}

.xxlarge {
  font-size: 8vh;
}

.bold {
  font-weight: bold;
}

.primary-color {
  background-color: $primary;
  color: $secondary !important;
}

.primary-color-dark {
  background-color: $primary-dark;
  color: $secondary;
}

.primary-color-text {
  color: $primary !important;
}

a.primary-color-text:hover {
  color: $primary-dark !important;
}

.secondary-color {
  background-color: $secondary;
  color: $primary !important;
}

.secondary-color-text {
  color: $secondary !important;
}

a.secondary-color-text:hover {
  color: $secondary-dark !important;
}

.text-color {
  color: $text-color;
}

a.text-color:hover {
  color: $text-color-dark;
}

.banner {
  position: relative;
  z-index: 4;
  background-color: $primary;
  color: $text-color;
}

.setup-img {
  z-index: 4;
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .setup-img {
    max-width: 100%;
    height: 20vh;
  }
}

p a {
  color: $secondary;
  text-decoration: underline;
}

p {
  text-align: justify;
}

a:hover {
  color: $secondary-dark;
}

.certificates li:before {
  content: "\f0a3";
  font-family: "Font Awesome 5 Free";
  margin-left: -20px;
  margin-right: 10px;
}

.services {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../img/logo(with stroke).png");
  background-size: 100% !important;
}

.gallery {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../img/banner-crop.webp");
}

.contact {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../img/profilepic.webp");
}

.highlight-container,
.pmu {
  width: 100vw;
}

.highlight {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  height: 125px;
  transition: 0.4s;
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Place text in the middle of the image */
.highlight-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.client-img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.lazy-parent {
  min-height: 200px;
}

.lazy {
  display: none;
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

.modal {
  display: none;
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  z-index: 11;
}

#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .modal-img {
    width: 100%;
  }
}

.contact-cont i {
  float: left;
}

.follow i {
  float: left;
}

.insta,
.insta:hover {
  color: #cf426d;
}

.facebook,
.facebook:hover {
  color: #3877ea;
}

.contact-cont a {
  color: $text-color;
}
