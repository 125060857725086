//$primary: #EEEBEB; //very light pink
//$primary: #E6DCDB; //light pink
$primary: #e9d5d1; //fairly pink
//$primary: #DAC3BF; //pink

//$primary: #EFEDEE; //very light grey (stroke)
//$primary: #ecebeb; //light grey (stroke)
//$primary: #e0e0e0; //fairly grey (stroke)
//$primary: #d1cfcf; //fairly grey (stroke)

$primary-dark: #d6c3c0;
$secondary-light: #efedee;
$secondary: #d2968c;
$secondary-dark: #94584f;
//$text-color: black;
$text-color: #616060;
$text-color-dark: #525252;
$margin: 20px;

@font-face {
  font-family: "Arapey";
  src: url("../font/arapey-regular.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Rochester";
  src: url("../font/Rochester-Regular.ttf") format("truetype");
  font-display: block;
}
